.header {
    border-bottom: 1px solid var(--color-grey-stroke);
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 100;
    
    transition: all .3s;
}
/* @media (max-width: 1023px) {
    .header_hide {
        transform: translateY(-100%);
    }
} */
.header_hide {
    transform: translateY(-100%);
}

.header_sticky {
    position: sticky;
    top: 0;
    transform: translateY(0);
}


.header_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 15px 0; */
    height: 80px;
}
.logo .logo_cobranded{
    /* width: clamp(90px, 10.7638vw, 155px); */
    width: 154px;
}
@media (max-width: 1023px) {
    .logo .logo_cobranded{
        width: 114px
    }
}
@media (max-width: 767px) {
    .logo .logo_cobranded{
        /* width: 45px; */
        width: 105px;
    }
}
.logo .logo_gac{
    width: clamp(180px, var(--clamp-size-300));
}

.logo .logo_msc {
    width: clamp(240px, 16.77083333vw, 322px);
}
/* @media (max-width: 479px) {
    .logo .logo_gac{
        width: 90px;
    }  
} */
.header_controls {
    display: flex;
    height: 100%;
}
.header_controls_item {
    padding: 15px clamp(20px, 2.0833vw, 30px);
    display: flex;
    align-items: center;
    position: relative;
}
.header_controls_item + .header_controls_item {
    border-left: 1px solid var(--color-grey-stroke);    
}

.btn_location {
    font-size: clamp(14px, var(--clamp-size-16));
    padding: 10px 20px;
    background-color: var(--color-dark-raspberry-secondary);
}




.control_box {
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    gap: 10px;
    color: var(--color-text-blue);
    font-size: 16px;
    line-height: 1;
}
.control_box_location_container {
    align-items: flex-start;
}
.control_box_icon {
    width: 33px;
}
.control_box_location_container .control_box_icon {
    width: 20px;
}
.control_box_icon svg {
    width: 100%;
    height: auto;
}
.control_box_icon svg path {
    fill: var(--color-text-blue)
}
.control_box_location_container .control_box_icon path {
    fill: var(--color-dark-raspberry)
}
.text_block {
    text-align: left;
}
.text_block button {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    display: block;
    text-align: left;
}

.btn_subtitute {
    display: flex;
    gap: 5px;
    margin-top: 3px;
    align-items: center;
}
.btn_subtitute .btn_subtitute_span {
    color: inherit;
    text-decoration: underline;
    text-transform: unset;
    font-family: var(--clamp-brandon-text-regular);
}
.text_block span {
    display: block;
    font-size: 12px;
    color: var(--color-dark-raspberry-secondary);    
    text-transform: uppercase;
    font-family: var(--font-brandon-text-bold);
}
.text_block .btn_underline {
    color: var(--color-text-blue);
    font-family: var(--clamp-brandon-text-regular);
    display: flex;
    gap: 5px;
    align-items: center;
}

.mobile_location_header {
    background-color: var(--color-dark-raspberry);
    padding: 7px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.mobile_location_header_address {
    display: flex;
    align-items: center;
    gap: 5px;
}
.mobile_location_header_delivery_type {
    text-transform: capitalize;
    font-family: var(--font-brandon-text-bold);
}
.control_box_mobile {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}
.mobile_location_header_no_loc {
    justify-content: center;
}
.mobile_location_header_no_loc button {
    font-family: var(--font-brandon-text-bold);
    font-size: 16px;
}
.control_box_location {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 15px;
    flex: 1;
}
.control_box_location_text {
    /* max-width: 165px; */
    max-width: clamp(160px, 50vw, 560px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}
.white {
    color: #fff;
    font-size: 14px;
    font-family: 'Brandon Text Bold';
}

.user_logged_in {
    display: flex;
    align-items: center;
    gap: 10px;
}
@media (max-width: 1023px) {
    /* .text_block {
        display: none;
    } */
    .header_controls_item {
        padding: 2px 10px;
    }
    .header_inner {
        height: 60px;
    }
    .header_controls {
        height: 60px;
    }
}

@media (max-width: 767px) {
    .text_block:not(.text_block_sign_in) {
        display: none;
    }
    .header_controls_item {
        border-left: 1px solid var(--color-grey-stroke);
        padding: 2px 20px;
    }
    .header_controls_item:last-child {
        padding-right: 0;
    }

    .text_block_sign_in span {
        display: none;
    }
    .user_logged_in {
        gap: 0;
    }
   
}


.text_block_address {
    /* width: 105px; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
}


.loader_container {
    padding: 10px;
    text-align: center;
}

.change_selections_container_outer {
    position: absolute;
    top: 100%;
    max-height: calc(100vh - 80px);
    /* overflow-y: auto; */

    filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.15));
}
.change_selections_container {
    padding: 20px;
    border-top: 2px solid var(--color-pink);
    border-bottom: 2px solid var(--color-pink);
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.16));
    width: 400px;
    
}
.change_selections_header {
    display: flex;
    gap: 5px;
    border-bottom: 1px solid var(--color-grey-stroke);
    padding-bottom: 15px;
    font-size: 14px;
}
.change_selections_title {
    font-family: var(--font-brandon-text-bold);
    margin-bottom: 8px;
}
.highlight {
    color: var(--color-dark-raspberry-secondary);
}
.change_selections_footer {
    padding-top: 24px;
    text-align: center;
}
.change_selections_button_container {
    margin-top: 2px;
}

.btn_svg_18 svg {
    width: 18px;
    height: 18px;
}

.switch_button {
    font-size: 14px;
    color: var(--color-dark-raspberry);
    font-family: var(--font-brandon-text-bold);
    margin-top: 10px;
}

.change_selections_body {
    padding-top: 15px;
}

.change_selections_main_controls {
    display: flex;
}
.change_selections_main_controls button {
    flex: 1;
    gap: 5px;
}
.change_selections_main_controls button:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.change_selections_main_controls button:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.change_selections_date_controls {
    margin-top: 15px;
    /* display: flex; */
}

.change_selections_date_confirm {
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

@media (max-width: 767px) {
    .change_selections_container_outer {
        right: 0;
        left: 0;
        /* filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.15)); */
    }
    .change_selections_container {
        width: 100%;
        /* border-bottom: 2px solid var(--color-pink); */
        
    }
}


.account_helper_text {
    margin-top: 3px;
    display: block;
    text-decoration: underline;
    color: var(--color-text-blue);
    font-family: var(--clamp-brandon-text-regular);
    font-size: 12px;
}
@media (max-width: 767px) {
    .account_helper_text {
        display: none;
    }
}


.terms_container p + p{
    margin-top: 20px;
}

.terms_container p a {
    text-decoration: underline;
}

.terms_container_footer {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
}