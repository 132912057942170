.banner {
    /* height: 250px; */
    height: clamp(180px, 19.2156vw, 490px);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-repeat: repeat-x;
    /* padding-bottom: 59px; */
    
}
.banner_inner_wrapper {
    display: flex;
    height: 100%;
    justify-content: space-between;
    /* height: 215px; */
    height: clamp(130px, 17.2549vw, 440px);
    width: 100%;
    position: relative;
}

.graphic_container {
    height: 145%;
    position: absolute;
    top: 50%;
    width: 25%;
    transform: translateY(-50%);
}
.graphic_container_left {
    /* transform: translateX(-10%) translateY(-13%); */
    left: -8%;    
    top: 42%
}

.banner_inner_wrapper_cobrand .graphic_container_left {
    top: 50%;
    width: 30%;
    height: 165%;
}
.graphic_container_right {
    /* transform: translateX(10%) translateY(-13%); */
    right: -6%;
    top: 56%;
}
.graphic_container_right img {
    transform: rotate(-15deg);
}
/* .graphic_container_left img {
    transform: rotate(60deg);
} */

.graphic_container_msc {
    height: 145%;
    position: absolute;
    top: 50%;
    width: 34%;
    transform: translateY(-50%);
}
.graphic_container_msc.graphic_container_left {
    /* transform: translateX(-10%) translateY(-13%); */
    /* left: -10%;
    top: 50%; */
    left: -10%;
    top: 50%;
    width: 30%;
    height: 165%;
}
.graphic_container_msc.graphic_container_right {
    /* transform: translateX(10%) translateY(-13%); */
    /* right: -10%;
    top: 56%; */
    right: -17%;
    top: 58%;
    height: 196%;
    width: 47%;
}
@media (max-width: 900px) {
    .graphic_container {
        width: 238px;
        height: 300px;
    }
    .banner_inner_wrapper_cobrand .graphic_container_left {
        width: 250px;
        height: 300px;
        left: -13%;
    }
    /* .graphic_container_right {
        right: -10%;
    } */
}
@media (max-width: 650px) {
    .graphic_container {
        width: 200px;
        height: 300px;
    }
    /* .graphic_container_right {
        right: -10%;
    } */
}

@media (max-width: 620px) {
    .graphic_container_msc {
        width: 300px !important;
    }
    .graphic_container_msc.graphic_container_left {
        left: -130px !important;
    }
    .graphic_container_msc.graphic_container_right {
        right: -130px !important;
    }
}

@media (max-width: 500px) {
    .graphic_container {
        width: 170px;
        height: 300px;
    }
    .graphic_container_right {
        right: -14%;
    }
    .graphic_container_right img {
        transform: rotate(0);
    }
    .graphic_container_left {
        width: 170px;
        left: -14%;
    }
    .banner_inner_wrapper_cobrand .graphic_container_left {
        left: -21%;
        width: 208px;
    }
    /* .graphic_container_left img {
        transform: rotate(75deg);
    } */
    /* .graphic_container_right {
        right: -10%;
    } */
}
.banner_inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 100%;
}
.title {
    font-size: clamp(36px, var(--clamp-size-70));
    color: var(--color-dark-raspberry);
    text-transform: uppercase;
    line-height: 1.1;
    font-family: var(--font-bourton-hand-base);
    font-weight: normal;
    text-align: center;
}

.directory .title {
    color: var(--color-cocoa);
}

.banner_bottom_line {
    background-position: bottom center;
    background-repeat: no-repeat;
    background-image: url(../../assets/Banner-Bottom-Line-mask.svg);
    background-repeat: repeat-x;
    padding-bottom: 698px;
    position: relative;
}
.banner_msc .banner_bottom_line {
    background-image: url(../../assets/Banner-Bottom-Line-mask-msc.svg);
}


.title_set {
    max-width: 60%;
    font-size: clamp(25px, var(--clamp-size-60));
}
@media (max-width: 900px) {
    .title_set {
        max-width: 45%;
    }
    .banner_set {
        height: 250px;
    }
    .banner_inner_wrapper_set {
        height: 250px;
    }
}
/* .banner_inner_wrapper_set {
    height: auto;
    
    padding: 40px 0;
} */


.banner_inner_set {
    flex-direction: column;
}

.supTitle {
    font-size: clamp(40px, var(--clamp-size-70));
    color: var(--color-dark-mint);
    font-family: var(--font-icing);
    line-height: 0.6;
    margin-bottom: 10px;
}

.subtitle {
    margin-top: 40px;
    font-size: clamp(18px, var(--clamp-size-24));
}